import React from 'react';
import loadable from '@loadable/component';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNLogo = loadable(() => import('containers/#kiniseko/Header/Logo'));
const NCLogo = loadable(() => import('containers/#nisekocentral/Header/Logo'));
const SNLogo = loadable(() => import('containers/#skye/Header/Logo'));

const Logo = () => (
  <MatchOne>
    <Tenant id="kiniseko" component={KNLogo} />
    <Tenant id="nisekocentral" component={NCLogo} />
    <Tenant id="skye" component={SNLogo} />
  </MatchOne>
);

export default Logo;
