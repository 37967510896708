import PropTypes from 'prop-types';
import styled from 'styled-components';

const ThemedText = styled.span`
  font-size: ${props => props.fontSize};
  color: ${props => (props.color || props.theme.colors.primary)};
  font-weight: ${props => (props.bold ? 'bold' : '')};
`;

ThemedText.propTypes = {
  fontSize: PropTypes.string,
  color: PropTypes.string,
  bold: PropTypes.bool,
};

ThemedText.defaultProps = {
  fontSize: '1em',
  bold: false,
};

export default ThemedText;
