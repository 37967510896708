// Custom moment date formats that used in the system
export default {
  en: {
    longDateFormat: {
      l: 'D MMM YYYY', // 29 Jul 2019
      ll: 'ddd D MMM', // Mon 29 Jul
      LL: 'dddd, Do MMM YYYY', // Monday, 29th July 2019
      LLL: 'dddd, Do MMMM', // Monday, 29th July
      LLLL: 'Do MMM YYYY (dddd)', // 29th Jul 2019 (Monday)
    },
  },
  ja: {
    longDateFormat: {
      l: 'YYYY年M月D日', // 2019年7月29日
      ll: 'M月D日 (ddd)', // 7月29日 (月)
      LL: 'YYYY年M月D日 (dddd)', // 2019年7月29日 (月曜日)
      LLL: 'M月D日 (dddd)', // 7月29日 (月曜日)
      LLLL: 'YYYY年M月D日 (dddd)', // 2019年7月29日 (月曜日)
    },
  },
};
