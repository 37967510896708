import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Confirmation = loadable(() => import(/* webpackChunkName: "Confirmation" */ './Confirmation'), {
  fallback: <Loader visible />,
});

const asyncConfirmation = ({ route }) => (
  <ErrorBoundary>
    <Confirmation route={route} />
  </ErrorBoundary>
);

asyncConfirmation.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncConfirmation;
