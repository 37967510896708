import get from 'lodash/get';

const DEFAULT_ORDER = 'sortPrice ASC';

const SORT_KEYS = {
  discount: 'options[0].rateDiscounts.percentage',
  floorArea: 'floorArea',
  gradeSort: 'gradeSort',
  name: 'name',
  random: 'random',
  sortPrice: 'options[0].sortPrice',
};

export default function sortProperties(properties, order) {
  const orderKeys = (order || DEFAULT_ORDER).split(' ');

  const sorted = properties.sort((room, anotherRoom) => {
    const attribute = SORT_KEYS[orderKeys[0]];
    const direction = orderKeys[1] === 'DESC' ? -1 : 1;

    const roomSortValue = get(room, attribute, '');
    const anotherRoomSortValue = get(anotherRoom, attribute, '');

    // Primary sorting by the specified attribute
    if (roomSortValue > anotherRoomSortValue) {
      return direction;
    }
    if (roomSortValue < anotherRoomSortValue) {
      return -direction;
    }

    const priceAttribute = SORT_KEYS.sortPrice;
    const roomPrice = get(room, priceAttribute, 0);
    const anotherRoomPrice = get(anotherRoom, priceAttribute, 0);

    // Secondary sorting by price (ascending)
    if (roomPrice > anotherRoomPrice) {
      return 1;
    }
    if (roomPrice < anotherRoomPrice) {
      return -1;
    }

    const randomAttribute = SORT_KEYS.random;
    const roomRandom = get(room, randomAttribute, '');
    const anotherRoomRandom = get(anotherRoom, randomAttribute, '');

    // Tertiary sorting by random value (ascending)
    if (roomRandom > anotherRoomRandom) {
      return 1;
    }
    if (roomRandom < anotherRoomRandom) {
      return -1;
    }

    // If all criteria are equal
    return 0;
  });

  return sorted;
}
