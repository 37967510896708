import sanitizeObject from '@htmniseko/htm-helpers/misc/sanitizeObject';
import { LOAD_PHONE_COUNTRIES_SUCCESS } from 'state/modules/phone/types';

export default () => () => next => action => {
  const sanitizedAction = sanitizeObject(action);

  if (sanitizedAction.type === LOAD_PHONE_COUNTRIES_SUCCESS) {
    // Set the Countries Phone Extension list in the PhoneInput Options format
    const result = action.result.map(({ code, extension, name }) => ({
      countryCode: code,
      extension,
      name,
    }));
    return next({ ...sanitizedAction, result });
  }

  return next(sanitizedAction);
};
