import types from './types';

const { OPEN, CLOSED, SET } = types;

const initialState = {
  open: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        open: true,
      };
    case CLOSED:
      return {
        open: false,
      };
    case SET:
      return {
        open: action.open,
      };
    default:
      return state;
  }
}
