import React from 'react';
import loadable from '@loadable/component';

import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Maintenance = loadable(() => import(/* webpackChunkName: "Maintenance" */ './Maintenance'), {
  fallback: <Loader visible />,
});

const asyncOffline = () => (
  <ErrorBoundary>
    <Maintenance />
  </ErrorBoundary>
);

export default asyncOffline;
