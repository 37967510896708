import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import loadable from '@loadable/component';
import { close, open } from 'state/modules/drawer';
import { MatchOne, Tenant } from 'containers/Tenancy';
import styles from './DrawerMenu.scss';

const KNDrawerMenu = loadable(() => import('containers/#kiniseko/DrawerMenu/DrawerMenu'));
const NCDrawerMenu = loadable(() => import('containers/#nisekocentral/DrawerMenu/DrawerMenu'));
const SNDrawerMenu = loadable(() => import('containers/#skye/DrawerMenu/DrawerMenu'));

@connect(state => ({ isOpen: state.drawer.open }), { close, open })
export default class DrawerMenu extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    open: PropTypes.func.isRequired,
  };

  // TODO Use OutsideClick wrapper instead of custom onClick
  render() {
    const { isOpen, close, open } = this.props;
    return (
      <nav className={styles.navigation} style={isOpen ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <div className={isOpen ? styles.containerAfter : styles.containerBefore}>
          <MatchOne>
            <Tenant id="kiniseko" component={KNDrawerMenu} />
            <Tenant id="nisekocentral" component={NCDrawerMenu} />
            <Tenant id="skye" component={SNDrawerMenu} />
          </MatchOne>
        </div>
        {/* eslint-disable-next-line */}
        <div className={(isOpen) ? styles.maskAfter : styles.maskBefore} onClick={(isOpen) ? close : open} />
      </nav>
    );
  }
}
