import React from 'react';
import loadable from '@loadable/component';
import Nav from 'browser/Nav';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNMainNavigation = loadable(() => import('containers/#kiniseko/Header/MainNavigation'));
const NCMainNavigation = loadable(() => import('containers/#nisekocentral/Header/MainNavigation'));
const SNMainNavigation = loadable(() => import('containers/#skye/Header/MainNavigation'));

const MainNavigation = () => (
  <Nav>
    <MatchOne>
      <Tenant id="kiniseko" component={KNMainNavigation} />
      <Tenant id="nisekocentral" component={NCMainNavigation} />
      <Tenant id="skye" component={SNMainNavigation} />
    </MatchOne>
  </Nav>
);

export default MainNavigation;
