import PropTypes from 'prop-types';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  max-width: ${props => props.theme.header.width};
  margin: ${({ margin }) => {
    switch (margin) {
      case 'small': return '1em';
      case 'medium': return '2em';
      case 'large': return '4em';
      default: return margin;
    }
  }};
`;

Container.propTypes = {
  margin: PropTypes.string,
};

Container.defaultProps = {
  margin: '0 auto',
};

export default Container;
