import PropTypes from 'prop-types';
import React from 'react';
import { logout } from 'utils/misc';

const Tenant = ({ component, children }) => (
  // Navigate to the logout path to hit the logout handling in the application server-side
  children || React.createElement(component, { onLogout: () => logout() })
);

Tenant.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object, // Cookies Facet is an object
  ]),
  children: PropTypes.node,
};

export default Tenant;
