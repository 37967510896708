import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigationType, useSearchParams } from 'react-router-dom';

import { replaceFilters } from '@htmniseko/htm-components/Facet/reducer';
import isNumeric from '@htmniseko/htm-helpers/math/isNumeric';

import { load } from 'state/modules/search/actions';
import paxFilterKeys, { defaultPax } from '_config/constants/facets/pax';
import useFacet from './useFacet';

const useSearch = ({ setFacetsOnPop } = {}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const facetsFilters = useSelector(state => state.facets.filters);
  const properties = useSelector(state => state.search.properties);
  const isLoaded = useSelector(state => state.search.loaded);
  const isLoading = useSelector(state => state.search.loading);
  const dispatch = useDispatch();
  const [, setAdultFilter] = useFacet(paxFilterKeys.adults);

  const search = useCallback(({ searchFilters, syncFilters } = { searchFilters: null, syncFilters: true }) => {
    const filters = searchFilters ? { ...searchFilters } : { ...facetsFilters };

    if (!filters || Object.keys(filters).length === 0) {
      // Do nothing if no filters are provided, the properties are already loaded and initialized by SSR
      return;
    }

    // Verify if the adult filters was set, if not use the default value
    if (!isNumeric(filters[paxFilterKeys.adults])) {
      setAdultFilter(defaultPax.adults);
      filters[paxFilterKeys.adults] = `${defaultPax.adults}`;
    }

    // Execute the search action
    const searchAction = load(filters);
    dispatch(searchAction);

    // Set the filters on the URL
    if (syncFilters) {
      setSearchParams(filters);
    }
  }, [dispatch, facetsFilters, setAdultFilter, setSearchParams]);

  useEffect(() => {
    if (setFacetsOnPop && navigationType === 'POP') {
      const historyFilters = {};
      // Set all the URL search parameters on the Facets Filters when he users executes the Browser's back or forward navigation
      // eslint-disable-next-line no-restricted-syntax
      for (const filterKey of searchParams.keys()) {
        historyFilters[filterKey] = searchParams.get(filterKey);
      }
      const replaceFiltersAction = replaceFilters(historyFilters);
      dispatch(replaceFiltersAction);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationType, searchParams]);

  useEffect(() => {
    // Initialize NC properties since it was not loaded by SSR
    if (Object.keys(facetsFilters).length === 0 &&
        properties.length === 0 &&
        // Prevent loading the properties again once it's loaded
        !isLoaded && !isLoading
    ) {
      dispatch(load());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return search;
};

export default useSearch;
