const types = {
  LOAD: 'app/search/LOAD',
  LOAD_SUCCESS: 'app/search/LOAD_SUCCESS',
  LOAD_FAIL: 'app/search/LOAD_FAIL',
  SET_DETAILS: 'app/search/SET_DETAILS',
  SHOW_FLOORPLAN: 'app/search/SHOW_FLOORPLAN',
  HIDE_FLOORPLAN: 'app/search/HIDE_FLOORPLAN',
  SORT: 'app/search/SORT',
  SEND_CONTACT_REQUEST: 'app/search/SEND_CONTACT_REQUEST',
  SEND_CONTACT_REQUEST_SUCCESS: 'app/search/SEND_CONTACT_REQUEST_SUCCESS',
  SEND_CONTACT_REQUEST_FAIL: 'app/search/SEND_CONTACT_REQUEST_FAIL',
  RESET_CONTACT_REQUEST: 'app/search/RESET_CONTACT_REQUEST',
};

export default types;
