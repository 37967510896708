import { useCallback, useMemo, useState } from 'react';
import useIsDomAvailable from './useIsDomAvailable';
import useMutationObserver from './useMutationObserver';

// Hooks that tells if the application has been translated by Google or Microsoft Translators
const useTranslated = () => {
  const [translated, setTranslated] = useState(false);
  const isDomAvailable = useIsDomAvailable();

  const htmlNode = isDomAvailable ? document.getElementsByTagName('html')[0] : null;

  // Function to be executed whenever there's an update on the class attribute of the HTML root element
  const onHtmlClassUpdated = useCallback(mutations => {
    if (Array.isArray(mutations) && mutations.length === 1) {
      // If Google Translate has translated the application, translated-ltr or translated-rtl classes are added to the HTML element
      const classNames = mutations[0].target.className.split(' ');
      if (classNames.includes('translated-ltr') || classNames.includes('translated-rtl')) {
        setTranslated(true);
      }
    }
  }, []);

  // Function to be executed whenever there's an update on the class attribute of the HTML root element
  const onMsTextHashFound = useCallback(mutations => {
    // If Microsoft Translator has translated the application, the _msttexthash is added to any translated node.
    if (Array.isArray(mutations) && mutations.length > 0) {
      setTranslated(true);
    }
  }, []);

  // Observe updates possibly triggered by Google Translate
  useMutationObserver(htmlNode, onHtmlClassUpdated, { attributes: true, attributeFilter: ['class'] });

  // Observe updates possibly triggered by Microsoft Translator
  useMutationObserver(htmlNode, onMsTextHashFound, { attributes: true, attributeFilter: ['_msttexthash'], subtree: true });

  // Return translation state
  return useMemo(() => translated, [translated]);
};

export default useTranslated;
