import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 */
const useOutsideClickHandler = (ref, onClose) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClose]);
};

/**
 * Component that alerts if you click outside of it
 */
const OutsideClickHandler = props => {
  const wrapperRef = useRef(null);
  useOutsideClickHandler(wrapperRef, props.onClose);

  return <div ref={wrapperRef}>{props.children}</div>;
};

OutsideClickHandler.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default OutsideClickHandler;
