import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import DrawerMenu from 'containers/DrawerMenu';
import styles from './Drawer.scss';

const Drawer = ({ children }) => {
  const isDrawerOpen = useSelector(state => state.drawer.open);

  return (
    <div>
      <DrawerMenu />
      <div className={(isDrawerOpen) ? styles.transformClose : styles.transformOpen}>
        {children}
      </div>
    </div>
  );
};

Drawer.propTypes = {
  children: PropTypes.node,
};

export default Drawer;
