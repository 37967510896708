import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import allLocales from '_config/locales';

const useLocale = () => {
  const { locale } = useIntl();
  const location = useLocation();

  return useMemo(() => {
    const isEnglish = locale === allLocales.english.code;

    const changeLocale = () => {
      const localePath = isEnglish ? allLocales.japanese.code : allLocales.english.code;
      window.location.href = `${localePath}${location}`;
    };

    return { changeLocale };
  }, [locale, location]);
};

export default useLocale;
