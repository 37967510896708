/* eslint import/prefer-default-export: "off" */
const breakpoints = require('../_config/breakpoints');

// Iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce((acc, key) => {
  const func = (...args) => `
    @media (min-width: ${breakpoints[key] / 16}em) {
      ${args}
    }
  `;
  return { ...acc, [key]: func };
}, {});
