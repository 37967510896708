import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const AdditionalPayment = loadable(() => import(/* webpackChunkName: "AdditionalPayment" */ './AdditionalPayment'), {
  fallback: <Loader visible />,
});

const asyncAdditionalPayment = ({ route }) => (
  <ErrorBoundary>
    <AdditionalPayment route={route} />
  </ErrorBoundary>
);

asyncAdditionalPayment.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncAdditionalPayment;
