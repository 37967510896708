import types from './types';

const { TOGGLE_MOBILE_FACET, SET_MOBILE_FACET } = types;

export function toggleMobileFacets() {
  return { type: TOGGLE_MOBILE_FACET };
}

export function setMobileFacetToggle(isOpen) {
  return {
    type: SET_MOBILE_FACET,
    isFacetsOpen: isOpen,
  };
}
