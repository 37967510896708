import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { report } from 'state/modules/error';
import ErrorMessage from './ErrorMessage';

export class ErrorBoundary extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    report: PropTypes.func.isRequired,
  };

  static defaultProps = {
    children: null,
  };

  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { report } = this.props;
    const { message, stack } = error;
    const { componentStack } = errorInfo;
    report(message, stack, componentStack);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    // Check if there's an error to render
    if (hasError) {
      return <ErrorMessage />;
    }

    // Normally, just render children
    return children;
  }
}

export default connect(() => ({}), { report })(ErrorBoundary);
