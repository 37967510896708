import { canUseDOM } from 'utils/dom';
import { cartCookieConfig, consentCookieConfig } from '_config/constants/cookies';

function getCookie(name) {
  if (!canUseDOM()) {
    return undefined;
  }

  const cookieValue = document.cookie
    .split('; ')
    .find(row => row.startsWith(`${name}=`))
    ?.split('=')[1];

  return cookieValue;
}

export function getAcceptCookie() {
  return getCookie(consentCookieConfig.name);
}

export function getCartCookie() {
  return getCookie(cartCookieConfig.name);
}
