import React from 'react';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

import ErrorMessage from 'components/Errors/ErrorMessage';

const NotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Helmet title={formatMessage({ id: 'notfound.page.helmet' })} />
      <ErrorMessage titleId="notfound.page.title" messageId="notfound.page.subtext" />
    </>
  );
};

export default NotFound;
