import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const RateSelection = loadable(() => import(/* webpackChunkName: "RateSelection" */ './RateSelection'), {
  fallback: <Loader visible />,
});

const asyncRateSelection = ({ route }) => (
  <ErrorBoundary>
    <RateSelection route={route} />
  </ErrorBoundary>
);

asyncRateSelection.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncRateSelection;
