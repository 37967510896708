import React from 'react';
import loadable from '@loadable/component';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNFooter = loadable(() => import('containers/#kiniseko/Footer/Footer'));
const NCFooter = loadable(() => import('containers/#nisekocentral/Footer/Footer'));
const SNFooter = loadable(() => import('containers/#skye/Footer/Footer'));

const Footer = () => (
  <MatchOne>
    <Tenant id="kiniseko" component={KNFooter} />
    <Tenant id="nisekocentral" component={NCFooter} />
    <Tenant id="skye" component={SNFooter} />
  </MatchOne>
);

export default Footer;
