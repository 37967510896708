import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationStyled';
import { resetFilters } from '@htmniseko/htm-components/Facet';

import LocalizedLink from 'components/Common/LocalizedLink';
import ThemedText from 'browser/ThemedText';
import useSearch from 'hooks/search/useSearch';

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: ${props => (props.isMobile ? '0 auto' : '5em auto')};
  max-width: 1040px;
  padding: 1em;
`;

const StyledErrorTitle = styled.h2`
  font-size: 1.8em;
  font-weight: 700;
  margin: 1em auto;
  line-height: 100%;
`;

const StyledSubText = styled.h3`
  font-size: 1.25em;
  font-weight: 100;
  margin: 0.5em 0;
  line-height: 110%;
`;

const StyledUnorderedList = styled.ul`
  list-style-type: disc;
  margin: 0.75em 1em;
  text-align: left;
`;

const StyledListItem = styled.li`
  line-height: 150%;
`;

const ErrorMessage = ({ message, messageId, title, titleId }) => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const search = useSearch();
  const [searchClicked, setSearchClicked] = useState(false);
  const filters = useSelector(state => state.facets.filters);
  const screenType = useSelector(state => state.client.screenType);
  const tenantColor = useSelector(state => state.tenant.theme.text.highlight);
  const websiteUrlObj = useSelector(state => state.tenant.urls.website);
  const websiteUrl = websiteUrlObj[locale];

  useEffect(() => {
    if (searchClicked) {
      search();
    }
  }, [filters, search, searchClicked]);

  const handleNewSearchClick = () => {
    dispatch(resetFilters());
    setSearchClicked(true);
  };

  return (
    <StyledContainer isMobile={screenType === 'mobile'}>
      <StyledErrorTitle>
        { title }
        { !title && <FormattedTranslation id={titleId} /> }
      </StyledErrorTitle>
      <StyledSubText>
        <p>
          { message }
          { !message && <FormattedTranslation id={messageId} /> }
        </p>
      </StyledSubText>
      <StyledUnorderedList>
        <StyledListItem>
          {/* In most cases, errors could be triggered during the checkout flow. */}
          {/* Setting the previous search URL will avoid further errors. (i.e., from and to dates not set when trying to create a cart) */}
          <LocalizedLink to="/" onClick={handleNewSearchClick}>
            <ThemedText color={tenantColor} bold>
              <FormattedTranslation id="notfound.page.search" />
            </ThemedText>
          </LocalizedLink>
        </StyledListItem>
        <StyledListItem>
          <LocalizedLink to="payment/new">
            <ThemedText color={tenantColor} bold>
              <FormattedTranslation id="notfound.page.payment" />
            </ThemedText>
          </LocalizedLink>
        </StyledListItem>
        <StyledListItem>
          <LocalizedLink href={websiteUrl}>
            <ThemedText color={tenantColor} bold>
              <FormattedTranslation id="notfound.page.home" />
            </ThemedText>
          </LocalizedLink>
        </StyledListItem>
      </StyledUnorderedList>
    </StyledContainer>
  );
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  messageId: PropTypes.string,
  title: PropTypes.string,
  titleId: PropTypes.string,
};

ErrorMessage.defaultProps = {
  messageId: 'general.error.message',
  titleId: 'general.error.titleId',
};

export default ErrorMessage;
