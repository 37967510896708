const YYYYMMDD = /^(19|20)\d\d([-/])(0[1-9]|1[012])\2(0[1-9]|[12][0-9]|3[01])$/;
const MMDDYYYY = /^(0[1-9]|1[012])[-/](0[1-9]|[12][0-9]|3[01])[-/](19|20)\d\d$/;
const DDMMYYYY = /^(0[1-9]|[12][0-9]|3[01])[-/](0[1-9]|1[012])[-/](19|20)\d\d$/;

export default function isValidDate(date) {
  if (!date || typeof date !== 'string') {
    return false;
  }

  // Trim leading and trailing slashes
  const trimmedDate = date.replace(/^\/+|\/+$/g, '');

  // Check date to ensure they match any of the regex above
  return YYYYMMDD.test(trimmedDate) || MMDDYYYY.test(trimmedDate) || DDMMYYYY.test(trimmedDate);
}
