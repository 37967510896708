import { getMessage } from 'utils/errors';
import { LOAD_SUCCESS } from 'state/modules/search/types';

export default client => () => next => action => {
  if (action.type === LOAD_SUCCESS) {
    const { searchlogid } = action.headers;
    const { search } = window.location;
    // We ensure the user set stay dates, or other search arguments
    // Note: all values in the headers are string types
    // The API sends undefined, on getting to the header the browser converts every value to string so it becomes 'undefined'
    if (search && searchlogid && searchlogid !== 'undefined') {
      const data = { responseTime: new Date() };
      // Make a request to the API to add response time to the log
      client.patch(`/loggers/${searchlogid}`, { data })
        .then(() => next(action))
        .catch(error => {
          console.error('Search logger middleware error:', error);
          next({ ...action, error: getMessage(error) });
        });
    }
  }

  return next(action);
};
