import PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';

import CookiesConsent from 'containers/Cookies/CookiesConsent';
import ClientManager from 'containers/ClientManager';
import Drawer from 'containers/Drawer';
import Footer from 'containers/Footer';
import Header from 'containers/Header';
import ErrorDialog from 'components/Dialogs/ErrorDialog';
import TranslationDialog from 'components/Dialogs/TranslationDialog';
import FloorPlanModal from 'components/ListView/FloorPlanModal';
import AppRoutes from './AppRoutes';

import './reset.scss';
import './override.scss';
import './App.scss';

const StyledBody = styled.div`
  min-height: 700px;
`;

const App = ({ locale, messages }) => {
  const tenant = useSelector(state => state.tenant);

  const { link, meta, titleTemplate } = tenant?.config?.head || {};

  return (
    <ThemeProvider theme={tenant.theme}>
      <div id={tenant.id}>
        <Helmet
          link={link}
          meta={meta}
          titleTemplate={titleTemplate}
        />
        <IntlProvider
          defaultLocale="en"
          locale={locale}
          messages={messages}
        >
          <ClientManager />
          <Drawer>
            <Header />
            <StyledBody>
              <AppRoutes />
            </StyledBody>
            <Footer />
          </Drawer>
          <ErrorDialog />
          <FloorPlanModal />
          <TranslationDialog />
          <CookiesConsent />
        </IntlProvider>
      </div>
    </ThemeProvider>
  );
};

App.propTypes = {
  locale: PropTypes.oneOf(['en', 'ja']).isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default App;
