export const cartCookieConfig = {
  name: 'cart',
};

export const consentCookieConfig = {
  maxAge: 15552000, // 6 * 30 * 24 * 60 * 60 (6 months in seconds)
  name: 'accept',
  value: '%23alert1',
};

export const reloadBookingsCookieConfig = {
  maxAge: 60, // (60 seconds)
  name: 'reloadBookings',
  value: true,
};
