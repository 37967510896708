import { name as app } from 'appConfig';
import types from './types';

const { DISPLAY, HIDE, CLEAR, REPORT, REPORT_FAIL, REPORT_SUCCESS } = types;

export function display({ statusCode, message }) {
  return { type: DISPLAY, statusCode, message };
}

export function hide() {
  return { type: HIDE };
}

export function report(message, stack, componentStack) {
  const data = {
    app,
    message,
    stack,
    componentStack,
    referrer: window.location.href,
    url: window.location.origin,
    userAgent: window.navigator.userAgent,
  };
  return {
    types: [REPORT, REPORT_SUCCESS, REPORT_FAIL],
    promise: client => client.post('/error', { data }),
  };
}

export function clear() {
  return { type: CLEAR };
}
