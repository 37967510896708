import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const SearchPage = loadable(() => (import('./SearchPage')), {
  fallback: <Loader visible />,
});

const asyncSearchPage = ({ route }) => (
  <ErrorBoundary>
    <SearchPage route={route} />
  </ErrorBoundary>
);

asyncSearchPage.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncSearchPage;
