import React from 'react';
import loadable from '@loadable/component';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNHeaderContent = loadable(() => import('containers/#kiniseko/Header/HeaderContent'));
const NCHeaderContent = loadable(() => import('containers/#nisekocentral/Header/HeaderContent'));
const SNHeaderContent = loadable(() => import('containers/#skye/Header/HeaderContent'));

const HeaderContent = () => (
  <MatchOne>
    <Tenant id="kiniseko" component={KNHeaderContent} />
    <Tenant id="nisekocentral" component={NCHeaderContent} />
    <Tenant id="skye" component={SNHeaderContent} />
  </MatchOne>
);

export default HeaderContent;
