import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ClientManager, { setScreen } from '@htmniseko/htm-components/ClientManager';

@connect(state => ({ screenWidth: state.client.screenWidth }), { setScreen })
export default class ClientManagerWrapper extends Component {
  static propTypes = {
    screenWidth: PropTypes.number.isRequired,
    setScreen: PropTypes.func.isRequired,
  };

  render() {
    const { screenWidth, setScreen } = this.props;
    return (
      <ClientManager
        screenWidth={screenWidth}
        setScreen={setScreen}
      />
    );
  }
}
