import types from './types';

const { TOGGLE_MOBILE_FACET, SET_MOBILE_FACET } = types;

const initialState = {
  isFacetsOpen: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TOGGLE_MOBILE_FACET:
      return {
        ...state,
        isFacetsOpen: !state.isFacetsOpen,
      };
    case SET_MOBILE_FACET:
      return {
        ...state,
        isFacetsOpen: action.isFacetsOpen,
      };
    default:
      return state;
  }
}
