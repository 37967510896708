import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import loadable from '@loadable/component';

import Mask from 'browser/Mask';

// It's better load the child components asynchronously so the main content (white mask) doesn't unnecessarily increase the size of its parent component
// But prefetch them so they are already loaded and rendered instantly when needed
const AsyncLoadingBouncer = loadable(() => import(/* webpackPrefetch: true */ './LoadingBouncer'));
const AsyncLoadingSpinner = loadable(() => import(/* webpackPrefetch: true */ './LoadingSpinner'));

export const Loader = ({ isSearching, visible, opaque }) => {
  const tenantUsesLoader = useSelector(state => state?.tenant?.config?.showLoader);
  const [isWaitingLong, setIsWaitingLong] = useState(false);

  useEffect(() => {
    if (visible) {
      // If this component is still being rendered after 1.5 seconds, it means that we are waiting for a response on a long running operation
      setTimeout(() => {
        setIsWaitingLong(true);
      }, 1500);
      return;
    }
    // it's not visible, set waiting long to false
    setIsWaitingLong(false);
  }, [visible]);

  const getOpacityValue = () => {
    if ((isWaitingLong && tenantUsesLoader) || opaque) {
      return 1;
    }

    return 0.8;
  };

  return (
    <Mask opacity={getOpacityValue()} visible={visible}>
      {isWaitingLong && tenantUsesLoader && isSearching && <AsyncLoadingBouncer />}
      {isWaitingLong && !isSearching && <AsyncLoadingSpinner />}
    </Mask>
  );
};

Loader.propTypes = {
  isSearching: PropTypes.bool,
  visible: PropTypes.bool,
  opaque: PropTypes.bool,
};

Loader.defaultProps = {
  isSearching: false,
  visible: false,
  opaque: false,
};

export default Loader;
