import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const LocalizedLink = ({ children, className, href, to, onClick }) => {
  const { locale } = useIntl();

  if (to || typeof onClick === 'function') {
    const adjustedPath = to && to[0] !== '/' ? `/${to}` : to;
    return (
      <Link
        className={className}
        to={to ? `/${locale}${adjustedPath}` : null}
        onClick={onClick}
      >
        {children}
      </Link>
    );
  }

  return <a className={className} href={href}>{children}</a>;
};

LocalizedLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

export default LocalizedLink;
