import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Result = loadable(() => import(/* webpackChunkName: "Result" */ './Result'), {
  fallback: <Loader visible />,
});

const asyncResult = ({ route }) => (
  <ErrorBoundary>
    <Result route={route} />
  </ErrorBoundary>
);

asyncResult.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncResult;
