import reducer from './reducers';

export default reducer;

export {
  add,
  book,
  cleanUp,
  setPaymentError,
  clearError,
  saveGuest,
  savePayment,
  saveRate,
  resetSavedGuest,
  resetSavedPayment,
  resetSavedRate,
  setPaymentUnverified,
  clearUnverifiedPayment
} from './actions';
