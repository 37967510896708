export default function register({ locale, tenantId, tenantAssets }) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      if (!Array.isArray(tenantAssets)) {
        return;
      }
      const assets = encodeURIComponent(tenantAssets.join(','));
      const SERVICE_WORKER = `/sw.js?locale=${locale}&tenantId=${tenantId}&assets=${assets}`;
      navigator.serviceWorker.register(SERVICE_WORKER).catch(err => {
        console.error('Error during service worker registration:', err);
      });
    });
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister();
    });
  }
}
