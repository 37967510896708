import React from 'react';
import styled from 'styled-components';

import Container from 'browser/Container';
import FullContainer from 'browser/FullContainer';
import BrowserHeader from 'browser/Header';
import { media } from 'utils/style';
import UpperNavigation from './UpperNavigation';
import Logo from './Logo';
import HeaderContent from './HeaderContent';
import MainNavigation from './MainNavigation';

const StyledSection = styled.section`
  display: block;
  text-align: center;
  padding-left: 2px;
  ${media.desktop`
    float: left;
  `}
`;

const StyledAside = styled.aside`
  display: block;
  text-align: center;
  ${media.desktop`
    float: right;
  `}
`;

const Header = () => (
  <FullContainer>
    <UpperNavigation />
    <BrowserHeader role="banner">
      <Container>
        <StyledSection>
          <Logo />
        </StyledSection>
        <StyledAside>
          <HeaderContent />
        </StyledAside>
      </Container>
    </BrowserHeader>
    <MainNavigation />
  </FullContainer>
);

export default Header;
