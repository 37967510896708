// More details on Google Analytics e-commerce events:
// https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtag
class GoogleAnalytics {
  constructor(action, { category, properties, rate } = {}) {
    this.action = action;
    this.category = category;
    this.properties = properties;
    this.rate = rate;
  }

  get impression() {
    if (!Array.isArray(this.properties)) {
      return [];
    }
    return this.properties.map(property => {
      const option = Array.isArray(property?.options) && property?.options.length > 0 ? property?.options[0] : {};
      return { id: `${property.id}`, name: property.name, price: option?.price };
    });
  }

  gtag() {
    // Check if the gtag function is available in the window object
    if (!window || !window.gtag) {
      return;
    }

    // Google Analytics was initialized correctly, execute the gtag function
    const items = this.impression;
    if (this.rate && items.length > 0) {
      items[0].rate = this.rate;
    }

    const data = {};
    if (items.length > 0) {
      data.items = items;
    }
    if (this.category) {
      data.event_category = this.category;
    }

    window.gtag('event', this.action, data);
  }
}

export function impression(property) {
  const option = Array.isArray(property?.options) && property?.options.length > 0 ? property?.options[0] : {};
  return { id: `${property.id}`, name: property.name, price: option?.price };
}

export function sendEvent(category, action) {
  const analytics = new GoogleAnalytics(action, { category });
  analytics.gtag();
}

export function sendSearchResultsEvent(properties) {
  const analytics = new GoogleAnalytics('view_item_list', { properties });
  analytics.gtag();
}

export function sendPropertySelectedEvent(property) {
  const analytics = new GoogleAnalytics('select_item', { properties: [property] });
  analytics.gtag();
}

export function sendPropertyDetailsViewEvent(propertyId) {
  const analytics = new GoogleAnalytics('view_item', { properties: [{ id: propertyId }] });
  analytics.gtag();
}

export function sendRateSelectedEvent(property, rate) {
  const analytics = new GoogleAnalytics('add_to_cart', { properties: [property], rate });
  analytics.gtag();
}

export function sendGuestDetailsEvent(property) {
  const analytics = new GoogleAnalytics('view_cart', { properties: [property] });
  analytics.gtag();
}

export function sendConfirmationEvent(property) {
  const analytics = new GoogleAnalytics('begin_checkout', { properties: [property] });
  analytics.gtag();
}

export function sendPaymentEvent(property) {
  const analytics = new GoogleAnalytics('purchase', { properties: [property] });
  analytics.gtag();
}
