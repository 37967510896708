import PropTypes from 'prop-types';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';
import Mask from 'browser/Mask';
import Button from 'browser/Button';
import styles from './Dialog.scss';

const Dialog = ({ actions, closeButtonText, isError, message, message2, title, visible, onClose }) => {
  const { formatMessage } = useIntl();
  const containerStyle = visible ?
    { visibility: 'visible', opacity: 1, transform: 'translate(-50%, 0px)' }
    : { visibility: 'hidden', opacity: 0, transform: 'translate(-50%, 100px)' };
  const iconName = isError ? 'exclamation-triangle' : 'exclamation-circle';
  const backgroundColor = isError ? '#ba3a3a' : '#ffba69';

  if (!visible) return null;

  return (
    <>
      <Mask visible={visible} color="rgba(0,0,0,0.5)" />
      <div className={styles.container} style={containerStyle}>
        <div className={styles.icon} style={{ backgroundColor }}>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button className={styles.close} type="button" onClick={onClose}>
            <FontAwesomeIcon icon="window-close" />
          </button>
          <FontAwesomeIcon icon={iconName} color="white" />
        </div>
        <div className={styles.message}>
          <h3>{title}</h3>
          <p>{message}</p>
          { message2 && <p>{message2}</p>}
        </div>
        <Button float="right" style={{ marginRight: '0.5em' }} onClick={onClose}>
          {closeButtonText || formatMessage({ id: 'dialog.button.close' }) }
        </Button>
        {
          actions.map((action, index) => {
            const buttonKey = `dialogAction-${index}`;
            return (
              <Button float="right" style={{ marginRight: '0.5em' }} onClick={action.onClick} key={buttonKey}>
                {action.text}
              </Button>
            );
          })
        }
      </div>
    </>
  );
};

Dialog.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  closeButtonText: PropTypes.string,
  isError: PropTypes.bool,
  message: PropTypes.string.isRequired,
  message2: PropTypes.string,
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

Dialog.defaultProps = {
  actions: [],
  isError: false,
};

export default Dialog;
