import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import OutsideClickHandler from 'browser/OutsideClickHandler';

const StyledModalContainer = styled.div`
  display: ${({ visible }) => (visible === true ? 'flex' : 'none')};
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto; 
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
`;

const StyledModalContent = styled.div`
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
`;

export const Modal = ({ visible, children, onClose }) => {
  if (visible) {
    return (
      <StyledModalContainer visible>
        <StyledModalContent>
          <OutsideClickHandler onClose={onClose}>
            {children}
          </OutsideClickHandler>
        </StyledModalContent>
      </StyledModalContainer>
    );
  }

  return null;
};

Modal.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};

Modal.defaultProps = {
  visible: false,
};

export default Modal;
