import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Offline from 'facades/Offline/Offline';
import RateSelection from 'facades/Checkout/RateSelection';
import GuestDetails from 'facades/Checkout/GuestDetails';
import BookingConfirmation from 'facades/Checkout/Confirmation';
import BookingPayment from 'facades/Checkout/Payment';
import BookingResult from 'facades/Checkout/Result';
import NotFound from 'facades/NotFound/NotFound';
import AdditionalPayment from 'facades/Payment/AdditionalPayment';
import Payment from 'facades/Payment/Payment';
import SearchPage from 'facades/SearchPage';
import Maintenance from 'facades/Maintenance';

const AppRoutes = () => (
  <Routes>
    <Route path="/:locale" element={<SearchPage />} />
    <Route path="/:locale/checkout/rates" element={<RateSelection />} />
    <Route path="/:locale/checkout/details" element={<GuestDetails />} />
    <Route path="/:locale/checkout/confirm" element={<BookingConfirmation />} />
    <Route path="/:locale/checkout/payment" element={<BookingPayment />} />
    <Route path="/:locale/checkout/result" element={<BookingResult />} />
    <Route path="/:locale/payment" element={<Payment />} />
    <Route path="/:locale/payments" element={<Payment />} />
    <Route path="/:locale/payment/new" element={<AdditionalPayment />} />
    <Route path="/:locale/payments/new" element={<AdditionalPayment />} />
    <Route path="/:locale/maintenance" element={<Maintenance />} />
    <Route path="/:locale/offline" element={<Offline />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
