import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

class MatchOne extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    tenant: PropTypes.object.isRequired,
  };

  render() {
    const { children, tenant } = this.props;
    let matchedChild = null;
    React.Children.forEach(children, child => {
      if (!React.isValidElement(child)) {
        return;
      }

      const { id } = child.props;
      if (tenant.id === id && matchedChild === null) {
        matchedChild = child;
      }
    });
    return matchedChild;
  }
}

export default connect(state => ({ tenant: state.tenant }))(MatchOne);
