import React from 'react';
import loadable from '@loadable/component';
import { MatchOne, Tenant } from 'containers/Tenancy';

const KNUpperNavigation = loadable(() => import('containers/#kiniseko/Header/UpperNavigation'));
const NCUpperNavigation = loadable(() => import('containers/#nisekocentral/Header/UpperNavigation'));
const SNUpperNavigation = loadable(() => import('containers/#skye/Header/UpperNavigation'));

const UpperNavigation = () => (
  <nav>
    <MatchOne>
      <Tenant id="kiniseko" component={KNUpperNavigation} />
      <Tenant id="nisekocentral" component={NCUpperNavigation} />
      <Tenant id="skye" component={SNUpperNavigation} />
    </MatchOne>
  </nav>
);

export default UpperNavigation;
