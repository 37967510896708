import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const Payment = loadable(() => import(/* webpackChunkName: "Payment" */ './Payment'), {
  fallback: <Loader visible />,
});

const asyncPayment = ({ route }) => (
  <ErrorBoundary>
    <Payment route={route} />
  </ErrorBoundary>
);

asyncPayment.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncPayment;
