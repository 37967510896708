import superagent from 'superagent/dist/superagent.min';
import transform from './ApiQueryTransformer';

const methods = ['get', 'post', 'put', 'patch', 'delete'];

function formatUrl(baseUrl, path) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  // Prepend `/api` to relative URL, to proxy to API server.
  return `${baseUrl}/api/v0${adjustedPath}`;
}

export default class ApiClient {
  constructor({ baseUrl, locale, tenantId, guestSourceName, guestId, referrer } = {}) {
    this.baseUrl = baseUrl || '';
    this.locale = locale;
    this.tenantId = tenantId;
    this.guestSourceName = guestSourceName;
    this.guestId = guestId;
    this.referrer = referrer;

    methods.forEach(method => {
      this[method] = (path, { params, data, paymentService } = {}) => new Promise((resolve, reject) => {
        const request = superagent[method](formatUrl(this.baseUrl, path));
        console.error({ request });

        if (params) {
          request.query(transform(path, params, this.locale, this.tenantId));
        }

        // Override the referer as guest source if there is a specific guest source.
        if (this.guestSourceName) {
          request.set('g-source', this.guestSourceName);
        }
        if (this.guestId) {
          request.set('g-id', this.guestId);
        }

        if (this.tenantId) {
          request.set('X-Tenant', this.tenantId);
        }
        request.set('Content-Type', 'application/json');

        if (this.locale) {
          request.set('Accept-Language', this.locale);
        }
        if (this.token) {
          request.set('Authorization', this.token);
        }
        if (paymentService) {
          request.set('paymentsrv', paymentService);
        }

        if (data) {
          request.send(data);
        }

        request.end((err, res = {}) => {
          if (err) {
            return reject(res.body || err);
          }
          return resolve(res);
        });
      });
    });
  }

  setToken(token) {
    this.token = token;
  }
}
