const types = {
  DISPLAY: 'app/error/DISPLAY',
  HIDE: 'app/error/HIDE',
  CLEAR: 'app/error/CLEAR',
  REPORT: 'app/error/REPORT',
  REPORT_SUCCESS: 'app/error/REPORT_SUCCESS',
  REPORT_FAIL: 'app/error/REPORT_FAIL',
};

export default types;
