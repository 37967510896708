import { createStore, applyMiddleware, compose } from 'redux';
import createRootReducer from './modules/reducer';
import createMiddleware from './middleware/clientMiddleware';
import errorMiddleware from './middleware/errorMiddleware';
import orderMiddleware from './modules/order/middleware';
import searchMiddleware from './modules/search/middleware';
import phoneMiddleware from './middleware/phone';
import searchLoggerMiddleware from './middleware/searchLogger';

export default ({ initialState, client }) => {
  const middlewares = [
    createMiddleware(client),
    errorMiddleware(),
    searchLoggerMiddleware(client),
    searchMiddleware(),
    orderMiddleware(),
    searchMiddleware(),
    phoneMiddleware(),
  ];

  let finalCreateStore;
  // Build the store for development and test to include of redux dev tools.
  if ((process.env.NODE_ENV !== 'production' || process.env.STAGING === 'true') && typeof window !== 'undefined') {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle
    finalCreateStore = composeEnhancers(
      applyMiddleware(...middlewares)
    )(createStore);
  }
  else {
    // Build the store for production without redux dev tools
    finalCreateStore = applyMiddleware(...middlewares)(createStore);
  }

  // Redux store
  const store = finalCreateStore(
    createRootReducer(),
    initialState || {},
  );

  return { store };
};
