/*
  Global configurations, applies for all tenants
*/
module.exports = {
  name: 'HTM Storefront',
  appCode: 'portal',
  appCodeLocale: 'storefront',
  appCodeGuest: 'guest',
  host: process.env.HOST || 'localhost',
  port: process.env.PORT || 8880,
  zendeskKey: process.env.ZENDESK_KEY || '',
  listViewTenantIds: ['nisekocentral'],
};
