import types from './types';

const {
  LOAD, LOAD_SUCCESS, LOAD_FAIL,
  SET_DETAILS, SORT,
  SHOW_FLOORPLAN, HIDE_FLOORPLAN,
  SEND_CONTACT_REQUEST, SEND_CONTACT_REQUEST_SUCCESS, SEND_CONTACT_REQUEST_FAIL,
  RESET_CONTACT_REQUEST,
} = types;

export function toggleDetails(id) {
  return {
    type: SET_DETAILS,
    id,
  };
}

export function load(filters) {
  const params = {
    ...filters,
    seasonalImages: true,
    source: 'Storefront',
    requestTime: new Date().toISOString(),
  };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: client => client.get('/properties', { params }),
  };
}

export function showFloorPlan(imageUrl) {
  return {
    type: SHOW_FLOORPLAN,
    imageUrl,
  };
}

export function sendContactRequest(data) {
  return {
    types: [SEND_CONTACT_REQUEST, SEND_CONTACT_REQUEST_SUCCESS, SEND_CONTACT_REQUEST_FAIL],
    promise: client => client.post('/contacts', { data }),
  };
}

export function resetContactRequest() {
  return {
    type: RESET_CONTACT_REQUEST,
  };
}

export function hideFloorPlan() {
  return {
    type: HIDE_FLOORPLAN,
  };
}

export function sort(order) {
  return {
    type: SORT,
    order,
  };
}
