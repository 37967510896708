import types from './types';

const {
  ADD, ADD_SUCCESS, ADD_FAIL,
  SAVE_GUEST, SAVE_GUEST_SUCCESS, SAVE_GUEST_FAIL, SAVE_GUEST_RESET,
  SAVE_RATE, SAVE_RATE_SUCCESS, SAVE_RATE_FAIL, SAVE_RATE_RESET,
  SAVE_PAYMENT, SAVE_PAYMENT_SUCCESS, SAVE_PAYMENT_FAIL, SAVE_PAYMENT_RESET,
  SET_PAYMENT_UNVERIFIED, CLEAR_PAYMENT_UNVERIFIED,
  BOOK, BOOK_SUCCESS, BOOK_FAIL,
  CLEAN_UP,
  SET_PAYMENT_ERROR, CLEAR_ERROR,
} = types;

export function add(property, stay, pax, searchLogId = '', enquiry = false) {
  const data = {
    propertyId: property.id,
    stay,
    pax,
    enquiry,
    searchLogId,
  };
  return {
    types: [ADD, ADD_SUCCESS, ADD_FAIL],
    promise: client => client.post('/carts', { data }),
  };
}

export function saveGuest(cartId, { bedConfiguration, bedConfigurationText, guest, specialRequest }) {
  const data = {
    id: cartId,
    bedConfiguration,
    bedConfigurationText,
    guest,
    specialRequest,
  };
  return {
    types: [SAVE_GUEST, SAVE_GUEST_SUCCESS, SAVE_GUEST_FAIL],
    promise: client => client.patch('/carts/setDetails', { data }),
  };
}

export function savePayment(cartId, { folio, paymentReference }) {
  const data = { id: cartId, folio, paymentReference };
  return {
    types: [SAVE_PAYMENT, SAVE_PAYMENT_SUCCESS, SAVE_PAYMENT_FAIL],
    promise: client => client.patch('/carts/setDetails', { data }),
  };
}

export function saveRate(cartId, rateOptionId) {
  const data = { id: cartId, rateOptionId };
  return {
    types: [SAVE_RATE, SAVE_RATE_SUCCESS, SAVE_RATE_FAIL],
    promise: client => client.patch('/carts/setDetails', { data }),
  };
}

export function resetSavedGuest() {
  return {
    type: SAVE_GUEST_RESET,
  };
}

export function resetSavedPayment() {
  return {
    type: SAVE_PAYMENT_RESET,
  };
}

export function resetSavedRate() {
  return {
    type: SAVE_RATE_RESET,
  };
}

export function book(cartId, confirmation) {
  return {
    types: [BOOK, BOOK_SUCCESS, BOOK_FAIL],
    promise: client => client.post('/orders', { data: { cartId, confirmation } }),
  };
}

export function setPaymentError(error) {
  return {
    type: SET_PAYMENT_ERROR,
    error,
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR,
  };
}

export function cleanUp() {
  return {
    type: CLEAN_UP,
  };
}

export function setPaymentUnverified() {
  return {
    type: SET_PAYMENT_UNVERIFIED,
  };
}

export function clearUnverifiedPayment() {
  return {
    type: CLEAR_PAYMENT_UNVERIFIED,
  };
}
