import types from './types';

const { OPEN, CLOSED, SET } = types;

export function open() {
  return { type: OPEN };
}

export function close() {
  return { type: CLOSED };
}

export function set(open) {
  return { type: SET, open };
}
