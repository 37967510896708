import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationStyled';

const CookiesConsentBar = ({ url, onAccept }) => (
  <StyledSnackbar>
    <StyledContainer>
      <StyledMessage>
        <FormattedTranslation id="cookies.consent.usage" />
      </StyledMessage>
      <StyledLink href={url} target="_blank">
        <FormattedTranslation id="cookies.consent.moreInfo" />
      </StyledLink>
      <StyledButton type="button" onClick={onAccept}>
        <FormattedTranslation id="general.ok" />
      </StyledButton>
    </StyledContainer>
  </StyledSnackbar>
);

const StyledSnackbar = styled.div`
  background: ${props => props.theme.cookies.background};
  position: fixed;
  bottom: 0;
  width: calc(100% - 40px);
  color: white;
  padding: 12px 20px;
  text-align: center;
  z-index: 1000;
  transition: all 0.5s ease-out;
`;

const StyledContainer = styled.div`
  font-size: ${props => props.theme.cookies.fontSize};
  margin: 0 auto;
`;

const StyledMessage = styled.span`
  line-height: 25px;
  font-size: 1em;
  font-weight: 700;
  vertical-align: middle;

  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: underline;
  font-size: 0.8em;
  font-weight: 100;
  padding: 0 10px;
  vertical-align: middle;
`;

const StyledButton = styled.button`
  background: #00a54f;
  padding: 5px 10px;
  border-radius: 3px;
  color: white;
  border: none;
  cursor: pointer;
  user-select: none;
  font-size: 1em;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;

  &:hover {
    background: #009548;
  }
`;

CookiesConsentBar.propTypes = {
  url: PropTypes.string.isRequired,
  onAccept: PropTypes.func.isRequired,
};

export default CookiesConsentBar;
