const initialState = {
  user: null,
  guest: null,
  token: null,
};

export default function reducer(state = initialState) {
  // Nothing here, just for the state.
  return state;
}
