import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTripadvisor } from '@fortawesome/free-brands-svg-icons/faTripadvisor';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faVimeoSquare } from '@fortawesome/free-brands-svg-icons/faVimeoSquare';
import { faVimeoV } from '@fortawesome/free-brands-svg-icons/faVimeoV';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas';
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { faSearchPlus } from '@fortawesome/free-solid-svg-icons/faSearchPlus';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons/faWindowClose';
import { faEnvelope as faEnvelopeOutlined } from '@fortawesome/free-regular-svg-icons/faEnvelope';
import { fahtmAngleRight, fahtmEnvelope, fahtmPhone } from '@htmniseko/htm-ui/FortAwesomeSvgIcons';

export default function fontawesomeInit() {
  library.add(
    faFacebookF,
    faFacebookSquare,
    faInstagram,
    faTripadvisor,
    faTwitter,
    faVimeoSquare,
    faVimeoV,
    faAngleRight,
    faArrowDown,
    faArrowUp,
    faBars,
    faEnvelope,
    faEnvelopeOutlined,
    faExclamationTriangle,
    faExclamationCircle,
    faGlobeAmericas,
    faMapMarker,
    faMapMarkerAlt,
    faPhoneAlt,
    faSearchPlus,
    faSort,
    faSortDown,
    faSortUp,
    faSpinner,
    faTimes,
    faWindowClose,
    fahtmAngleRight,
    fahtmEnvelope,
    fahtmPhone,
  );
}
