import { useEffect, useState } from 'react';

const DEFAULT_OPTIONS = {
  attributes: true,
  attributeFilter: [],
  characterData: false,
  childList: false,
  subtree: false,
};

// Hook that implements the JavaScript DOM MutationObserver in React
const useMutationObserver = (targetEl, cb, options = DEFAULT_OPTIONS) => {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    const obs = new MutationObserver(cb);
    setObserver(obs);
  }, [cb]);

  useEffect(() => {
    if (!observer) {
      return () => {};
    }
    observer.observe(targetEl, options);
    return () => {
      // Disconnect the observer when the hook component unmounts
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, options, targetEl]);
};

export default useMutationObserver;
