import types from './types';

const {
  LOAD_PHONE_COUNTRIES, LOAD_PHONE_COUNTRIES_SUCCESS, LOAD_PHONE_COUNTRIES_FAIL,
  VALIDATE_PHONE, VALIDATE_PHONE_SUCCESS, VALIDATE_PHONE_FAIL,
} = types;

export function loadPhoneCountries() {
  return {
    types: [LOAD_PHONE_COUNTRIES, LOAD_PHONE_COUNTRIES_SUCCESS, LOAD_PHONE_COUNTRIES_FAIL],
    promise: client => client.get('Countries', { params: { withPhoneCode: true } }),
  };
}

export function validatePhone(phoneNumber, telephoneCode) {
  return {
    types: [VALIDATE_PHONE, VALIDATE_PHONE_SUCCESS, VALIDATE_PHONE_FAIL],
    promise: client => client.post('/phones/validatePhoneNumber', { data: { phoneNumber, telephoneCode } }),
  };
}

export function phoneValidated(result) {
  return {
    type: VALIDATE_PHONE_SUCCESS,
    result,
  };
}

export function phoneValidationFailed(error) {
  return {
    type: VALIDATE_PHONE_FAIL,
    error,
  };
}
