import styled from 'styled-components';

export default styled.header`
  display: block;
  clear: both;
  padding-top: ${props => props.theme.header.padding};
  padding-bottom: ${props => props.theme.header.padding};

  ::after {
    content: "";
    display: table;
    clear: both;
  }
`;
