import types from './types';

const { DISPLAY, HIDE, CLEAR } = types;

const initialState = {
  statusCode: null,
  message: null,
  visible: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY:
      return {
        statusCode: action.statusCode,
        message: action.message,
        visible: true,
      };
    case HIDE:
      return {
        ...state,
        visible: false,
      };
    case CLEAR:
      return {
        visible: false,
        statusCode: null,
        message: null,
      };
    default:
      return state;
  }
}
