import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import useTranslated from 'hooks/dom/useTranslated';
import useLocale from 'hooks/locale/useLocale';
import Dialog from './Dialog';

const TranslationDialog = () => {
  const { formatMessage } = useIntl();
  const [hideDialog, setHideDialog] = useState(false);
  const translated = useTranslated();
  const { changeLocale } = useLocale();

  const handleClose = () => {
    // Closes the dialog even if the application is still translated
    setHideDialog(true);
  };

  const handleSwitchLocale = () => {
    // Reload the application with different locale
    changeLocale();
  };

  return (
    <Dialog
      actions={[
        { text: formatMessage({ id: 'dialog.translation.official' }), onClick: handleSwitchLocale },
      ]}
      closeButtonText={formatMessage({ id: 'dialog.translation.continue' })}
      message={formatMessage({ id: 'dialog.translation.message' })}
      message2={formatMessage({ id: 'dialog.translation.message2' })}
      title={formatMessage({ id: 'dialog.translation.title' })}
      visible={translated && !hideDialog}
      onClose={handleClose}
    />
  );
};

export default TranslationDialog;
