import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

/* eslint-disable react/no-unused-prop-types */
const StyledOverlay = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: ${props => props.background};
  opacity: ${props => props.opacity};
  z-index: ${props => props.zIndex};
`;

const Mask = ({ children, color, opacity, visible, zIndex }) => {
  if (!visible) {
    return null;
  }
  return (
    <StyledOverlay
      background={children === null ? color : 'radial-gradient(circle, rgba(255,255,255,1) 10%, rgba(255,255,255,0.8) 35%)'}
      opacity={opacity}
      visible={visible}
      zIndex={zIndex}
    >
      {children}
    </StyledOverlay>
  );
};

Mask.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  opacity: PropTypes.number,
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
};

Mask.defaultProps = {
  children: null,
  color: '#FFF',
  opacity: 0.8,
  visible: false,
  zIndex: 2,
};

export default Mask;
