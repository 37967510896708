import React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

import FormattedTranslation from '@htmniseko/htm-ui/Intl/FormattedTranslationStyled';
import Container from 'browser/Container';

const StyledTitle = styled.h2`
  font-size: 2em;
  padding: 30px 10px 10px;
`;

const StyledDescription = styled.div`
  font-size: 1em;
  padding: 10px;
`;

const Offline = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <Helmet title={formatMessage({ id: 'pages.offline.title' })} />
      <Container>
        <StyledTitle><FormattedTranslation id="pages.offline.title" /></StyledTitle>
        <StyledDescription><FormattedTranslation id="pages.offline.description" /></StyledDescription>
      </Container>
    </>
  );
};

export default Offline;
