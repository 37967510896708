import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { clear, hide } from 'state/modules/error';
import Dialog from './Dialog';

const ErrorDialog = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const message = useSelector(state => state.error.message);
  const statusCode = useSelector(state => state.error.statusCode);
  const visible = useSelector(state => state.error.visible);

  const isError = statusCode % 400 >= 100;
  const title = formatMessage({
    id: `dialog.header.${isError ? 'error' : 'warn'}`,
  });

  const handleClose = () => {
    dispatch(hide());
    dispatch(clear());
  };

  return (
    <Dialog
      isError={isError}
      message={message}
      title={title}
      visible={visible}
      onClose={handleClose}
    />
  );
};

export default ErrorDialog;
