import queryTransformer from '@htmniseko/htm-helpers/api/queryTransformer';
import sortBy from '../_config/constants/sortBy';
import liftDistances from '../_config/constants/liftDistances';
import parseDate from '../utils/dates/parseDate';

// We want to always default to price and then random sorting
const SORT_BY_RANDOM = `filter[order]=${sortBy.random}`;
const DEFAULT_SORT_BY = `filter[order]=${sortBy.price}&${SORT_BY_RANDOM}`;

// Build an array of all allowed sort by values. (i.e.: price, price%20ASC, price%20DESC)
const getSortValues = sortBy => [sortBy, `${sortBy}%20ASC`, `${sortBy}%20DESC`];
const ALLOWED_SORT_BY = [];
Object.keys(sortBy).forEach(key => ALLOWED_SORT_BY.push(...getSortValues(sortBy[key])));

const transformMultiselect = (key, value) => {
  // These filters are constructed in a different way, and we don't want them to be encoded
  let values = decodeURIComponent(value).split(',');

  if (values.length === 1 && key === 'liftDistance') {
    // Lifts within 900m or 600m should also include those with lower distances
    const liftDistanceId = parseInt(values[0], 10);
    const distance = liftDistances.find(dist => dist.id === liftDistanceId);
    if (distance) {
      values = liftDistances.filter(d => d.value <= distance.value).map(d => d.id);
    }
  }

  if (values.length > 1) {
    return values.map(val => `filter[where][${key}Id][inq]=${val}`).join('&');
  }
  return values.map(val => `filter[where][${key}Id]=${val}`).join('&');
};

// Conversion rules that are used to convert Storefront URL to API URL
const CONVERTERS = {
  'check-in': (key, value) => {
    const decodedValue = decodeURIComponent(value);
    return `stay[from]=${parseDate(decodedValue)}`;
  },
  'check-out': (key, value) => {
    const decodedValue = decodeURIComponent(value);
    return `stay[to]=${parseDate(decodedValue)}&filter[where][bookable]=true`;
  },
  adults: {
    key: [/(adults)/i, 'pax[$1]'],
  },
  children: {
    key: [/(children)/i, 'pax[$1]'],
  },
  infants: {
    key: [/(infants)/i, 'pax[$1]'],
  },
  min: (key, value, locale, params) => {
    // If params also contain 'max', then we use between[min, max], otherwise price [gte] min
    if (params.max) {
      return `filter[where][price][between][0]=${value}`;
    }
    return `filter[where][price][gte]=${value}`;
  },
  max: (key, value, locale, params) => {
    // If params also contain 'min', then we use between[min, max], otherwise price [lte] max
    if (params.min) {
      return `filter[where][price][between][1]=${value}`;
    }
    return `filter[where][price][lte]=${value}`;
  },
  grade: transformMultiselect,
  location: transformMultiselect,
  accomtype: (k, v) => transformMultiselect('accomType', v),
  viewtype: (k, v) => transformMultiselect('view', v),
  liftdistance: (k, v) => transformMultiselect('liftDistance', v),
  aircon: { key: [/(aircon)/i, 'filter[where][amenities.aircon]'] },
  jacuzzi: { key: [/(jacuzzi)/i, 'filter[where][amenities.jacuzzi]'] },
  fireplace: { key: [/(fireplace)/i, 'filter[where][amenities.fireplace]'] },
  name: (key, value, locale) => {
    const namePart = `filter[where][or][0][name.${locale}][like]=${value}&filter[where][or][0][name.${locale}][options]=i`;
    const codePart = `filter[where][or][1][code][like]=${value}&filter[where][or][1][code][options]=i`;
    return `${namePart}&${codePart}`;
  },
  sortBy: (key, value, locale) => {
    // If the sort-by value is not valid, then default to price and random
    if (!ALLOWED_SORT_BY.includes(value)) {
      return DEFAULT_SORT_BY;
    }
    // If sorting by name, convert it and adjust it to match current locale
    if (value.includes(sortBy.name)) {
      const nameLocalized = value.replace(sortBy.name, `${sortBy.name}.${locale}`);
      return `filter[order]=${nameLocalized}`;
    }
    // When the apply sort is price, then add random as secondary sorting
    if (getSortValues(sortBy.price).includes(value)) {
      return `filter[order]=${value}&${SORT_BY_RANDOM}`;
    }
    // The default sorting (price & random) should always be added as secondary sorting
    return `filter[order]=${value}&${DEFAULT_SORT_BY}`;
  },
  portal: (key, value) => `${key}=${value}`,
  seasonalImages: (key, value) => `filter[${key}]=${value}`,
};

const transformQuery = queryTransformer(CONVERTERS);

export default function transform(path, params, locale, tenantId) {
  // Transform all the current API query parameters to HTM API query format
  const queries = transformQuery(params, locale);

  // Add in some (conditional) defaults when it is a properties search request
  // If we have stay dates (+ prices) and no sorting is defined, apply default sorting
  if (path.includes('properties')) {
    switch (tenantId) {
      case 'nisekocentral':
      case 'kiniseko':
      case 'skye':
      default: {
        if (!params.sortBy) {
          queries.push(DEFAULT_SORT_BY);
        }
        break;
      }
    }
  }

  return queries.join('&');
}
