/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/isEmpty';
// Utility method to unify all the error message to certain type of error object
export function getMessage(errorObj) {
  if (!errorObj || typeof errorObj !== 'object') {
    return { message: 'An unknown error occurred.' };
  }
  const error = errorObj?.error;
  // An error from our API
  if (typeof error === 'object' && !isEmpty(error)) {
    return {
      statusCode: error.statusCode,
      message: error.message,
      code: error.code || 'InternalServerError',
    };
  }

  // If it's none of the above, it hasn't come across yet.
  console.warn('Unhandled error type! See utils/errors.js and add handling for this', JSON.stringify(errorObj));
  return errorObj;
}
