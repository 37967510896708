import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CookiesConsentBar from 'components/Cookies/CookiesConsentBar';
import { getAcceptCookie } from 'utils/cookies/getCookie';
import { setAcceptCookie } from 'utils/cookies/setCookie';
import { useIntl } from 'react-intl';
import { canUseDOM } from 'utils/dom';

const CookiesConsent = () => {
  const { locale } = useIntl();
  const privacyUrl = useSelector(state => state?.tenant?.urls?.privacy?.[locale]);
  const [visible, setVisible] = useState(true);

  const handleAccept = () => {
    setAcceptCookie();
    // Set the state variable to false so the consent bar is immediately hidden
    setVisible(false);
  };

  const acceptCookie = getAcceptCookie();
  const open = visible && !acceptCookie;

  if (!open || !canUseDOM()) {
    return null;
  }

  return (
    <CookiesConsentBar
      open={!acceptCookie}
      url={privacyUrl}
      onAccept={handleAccept}
    />
  );
};

export default CookiesConsent;
