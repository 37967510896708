import { removeSessionStorageItem } from '@htmniseko/htm-helpers/webStorage/sessionStorage';
import { GUEST_DETAILS_SESSION_KEY } from '_config/constants/sessionStorage';

const SKIP_BEDCONFIG_ID = 92;

export function flattenObject(obj) {
  const flatObject = {};
  Object.keys(obj).forEach(key => {
    if (typeof obj[key] === 'object') {
      const subFlat = flattenObject(obj[key]);
      Object.keys(subFlat).forEach(subkey => {
        flatObject[`${key}.${subkey}`] = subFlat[subkey];
      });
    }
    else {
      flatObject[key] = obj[key];
    }
  });
  return flatObject;
}

// Sort bed configurations by the localized text;
// configurations come in the form of example:
// { value: 79, label: '1 King Size Bed' }
export function sortBedConfigs(bedConfigs) {
  return bedConfigs.sort((a, b) => {
    // Bed config ID 92 ("I'll decide later") should always be last
    if (a.value === SKIP_BEDCONFIG_ID) {
      return 1;
    }
    if (b.value === SKIP_BEDCONFIG_ID) {
      return -1;
    }
    // Sort bed configurations in alphabetic ascending order by text
    const labelA = a.text.toUpperCase();
    const labelB = b.text.toUpperCase();
    // 'b' goes first
    if (labelA < labelB) {
      return -1;
    }
    // 'a' goes first
    if (labelA > labelB) {
      return 1;
    }
    // 'a' and 'b' texts are equal
    return 0;
  });
}

// Get the domain from the hostname for a react-cookie cookie
export function getCookieDomain(hostname) {
  if (hostname === 'localhost') {
    return hostname;
  }
  // split the hostname to the domains inside it
  const domains = hostname.split('.');
  const topLevelDomain = domains.pop(); // .com or .localhost
  const mainDomain = domains.pop(); // eg. nisekocentral or kiniseko

  return [mainDomain, topLevelDomain].join('.');
}

// Logout is handled from the server side.
export function logout() {
  // Clear guest details after logging out
  removeSessionStorageItem(GUEST_DETAILS_SESSION_KEY);
  window.location.assign(`${window.location.origin}/logout`);
}
