const types = {
  ADD: 'app/order/ADD',
  ADD_SUCCESS: 'app/order/ADD_SUCCESS',
  ADD_FAIL: 'app/order/ADD_FAIL',
  BOOK: 'app/order/BOOKING',
  BOOK_SUCCESS: 'app/order/BOOKING_SUCCESS',
  BOOK_FAIL: 'app/order/BOOKING_FAIL',
  SAVE_GUEST: 'app/order/SAVE_GUEST',
  SAVE_GUEST_SUCCESS: 'app/order/SAVE_GUEST_SUCCESS',
  SAVE_GUEST_FAIL: 'app/order/SAVE_GUEST_FAIL',
  SAVE_GUEST_RESET: 'app/order/SAVE_GUEST_RESET',
  SAVE_PAYMENT: 'app/order/SAVE_PAYMENT',
  SAVE_PAYMENT_SUCCESS: 'app/order/SAVE_PAYMENT_SUCCESS',
  SAVE_PAYMENT_FAIL: 'app/order/SAVE_PAYMENT_FAIL',
  SAVE_PAYMENT_RESET: 'app/order/SAVE_PAYMENT_RESET',
  SAVE_RATE: 'app/order/SAVE_RATE',
  SAVE_RATE_SUCCESS: 'app/order/SAVE_RATE_SUCCESS',
  SAVE_RATE_FAIL: 'app/order/SAVE_RATE_FAIL',
  SAVE_RATE_RESET: 'app/order/SAVE_RATE_RESET',
  SET_PAYMENT_ERROR: 'app/order/SET_PAYMENT_ERROR',
  SET_PAYMENT_UNVERIFIED: 'app/order/SET_PAYMENT_UNVERIFIED',
  CLEAR_PAYMENT_UNVERIFIED: 'app/order/CLEAR_PAYMENT_UNVERIFIED',
  CLEAR_ERROR: 'app/order/CLEAR_ERROR',
  CLEAN_UP: 'app/order/CLEAN_UP',
};

export default types;
