import isValidDate from './isValidDate';

/**
 * This function parses date in the following formats:
 * YYYY-MM-DD, MM-DD-YYYY, DD-MM-YYYY
 * and returns a parsed date in this format: YYYY-MM-DD
 */
export default function parseDate(date) {
  if (!isValidDate(date)) {
    return '';
  }

  // Trim leading and trailing slashes
  const trimmedDate = date.replace(/^\/+|\/+$/g, '');

  // Handle splitting for dates with either `-` or `/` as separators
  let [day, month, year] = trimmedDate.slice(0, 10).split(/-|\//);

  // If day is YYYY
  if (day.length === 4) {
    // Swap day and year
    [year, day] = [day, year];
  }
  // If month is YYYY
  if (month.length === 4) {
    // Swap month and year
    [year, month] = [month, year];
  }
  if (parseInt(month, 10) > 12) {
    // Swap day and month
    [month, day] = [day, month];
  }
  return `${year}-${month}-${day}`;
}
