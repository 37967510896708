import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { toggleFilter } from '@htmniseko/htm-components/Facet';

const makeSelectFilter = () => createSelector(
  state => state.facets.filters,
  (_, attribute) => attribute,
  (filters, attribute) => filters[attribute]
);

const useFacet = attribute => {
  const selectFilter = useMemo(makeSelectFilter, []);
  const filter = useSelector(state => selectFilter(state, attribute));
  const dispatch = useDispatch();

  const setFilter = useCallback(value => {
    const toggle = toggleFilter(attribute, value);
    dispatch(toggle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return useMemo(() => [filter, setFilter], [filter, setFilter]);
};

export default useFacet;
