import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import Loader from 'components/Loader';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const GuestDetails = loadable(() => import(/* webpackChunkName: "GuestDetails" */ './GuestDetails'), {
  fallback: <Loader visible />,
});

const asyncGuestDetails = ({ route }) => (
  <ErrorBoundary>
    <GuestDetails route={route} />
  </ErrorBoundary>
);

asyncGuestDetails.propTypes = {
  route: PropTypes.object.isRequired,
};

export default asyncGuestDetails;
