import sanitizeObject from '@htmniseko/htm-helpers/misc/sanitizeObject';
import { getMessage } from 'utils/errors';

export default function clientMiddleware(client) {
  return ({ dispatch, getState }) => next => action => {
    if (typeof action === 'function') {
      return action(dispatch, getState);
    }

    // This will sanitize all strings and objects as DOM for security purposes;
    // All characters will be escaped (eg: &, >, <, ", ')
    const sanitizedAction = sanitizeObject(action);

    const { promise, types, ...rest } = sanitizedAction;
    if (!promise) {
      return next(sanitizedAction);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({ ...rest, type: REQUEST });

    const { auth } = getState();
    // Set the session access token in the API request, if available.
    client.setToken(auth ? auth.token : null);

    const actionPromise = promise(client);
    actionPromise.then(
      res => next({ ...rest, headers: res.headers, result: res.body, type: SUCCESS }),
      error => next({ ...rest, error: getMessage(error), type: FAILURE })
    ).catch(error => {
      console.error('MIDDLEWARE ERROR:', error);
      next({ ...rest, error: getMessage(error), type: FAILURE });
    });

    return actionPromise;
  };
}
