import types from './types';

const {
  INIT_SUCCESS, INIT_FAIL,
  PAYMENT_SUCCESS, PAYMENT_FAIL,
  SET_AMOUNT, CLEAN_UP,
} = types;

export function onInitSuccess(response) {
  return {
    type: INIT_SUCCESS,
    folio: response.folio,
  };
}

export function onInitFail(error) {
  return {
    type: INIT_FAIL,
    error,
  };
}

export function onPaymentSuccess(folio, amount, paymentReference) {
  return {
    type: PAYMENT_SUCCESS,
    folio,
    amount,
    paymentReference,
  };
}

export function onPaymentFail(error) {
  return {
    type: PAYMENT_FAIL,
    error,
  };
}

export function setAmount(amount) {
  return {
    type: SET_AMOUNT,
    amount,
  };
}

export function cleanUp() {
  return { type: CLEAN_UP };
}
