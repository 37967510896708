import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button`
  color: #fff;
  width: ${({ width }) => width};
  min-width: 100px;
  font-size: ${({ size }) => {
    switch (size) {
      case 'small': return '0.7em';
      case 'medium': return '0.8em';
      case 'large': return '0.9em';
      default: return '0.8em';
    }
  }};
  border: none;
  border-radius: 4px;
  padding: 0.7em 0.1em;
  margin: ${({ margin }) => margin};
  text-align: center;
  text-decoration: none;
  word-break: keep-all;
  display: inline-block;
  cursor: pointer;
  float: ${({ float }) => float};
  background-color: ${({ color, theme }) => {
    switch (color) {
      case 'green': return '#1EA659';
      case 'navigationBack': return theme.colors.button.default;
      case 'tertiary': return theme.colors.button.tertiary;
      default: return '#1EA659';
    }
  }};

  &:hover {
    background-color: ${({ color, theme }) => {
    switch (color) {
      case 'green': return '#23BF66';
      case 'navigationBack': return theme.colors['button:hovered'].default;
      case 'tertiary': return theme.colors['button:hovered'].tertiary;
      default: return '#23BF66';
    }
  }};
  }

  button[disabled=disabled],
  &:disabled {
    cursor: default;
    background-color: #ccc;
  }
`;

Button.propTypes = {
  color: PropTypes.string,
  float: PropTypes.string,
  size: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string,
};

Button.defaultProps = {
  float: 'none',
  width: 'auto',
  margin: '0 0 0.7em 0',
};

export default Button;
