import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { reducer as clientReducer } from '@htmniseko/htm-components/ClientManager';
import { reducer as facetReducer } from '@htmniseko/htm-components/Facet';

import auth from './auth';
import drawer from './drawer';
import error from './error';
import mobile from './mobile';
import order from './order';
import payment from './payment';
import search from './search';
import tenant from './tenant';
import phone from './phone';

const reducers = {
  form,

  auth,
  client: clientReducer,
  drawer,
  error,
  facets: facetReducer,
  mobile,
  order,
  payment,
  search,
  tenant,
  phone,
};

const createReducer = () => combineReducers({ ...reducers });

export default createReducer;
