import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideFloorPlan } from 'state/modules/search';
import { useIntl } from 'react-intl';
import Modal from 'components/Modal/Modal';
import styled from 'styled-components';

const StyledImage = styled.img`
  object-fit: cover;
  overflow: hidden;
  max-width: 100%;
  max-height: 700px;
  min-height: 185px;
  min-width: 185px;
`;

const FloorPlanModal = () => {
  const showFloorPlan = useSelector(state => state.search.showFloorPlan);
  const floorPlanImageUrl = useSelector(state => state.search.floorPlanImageUrl);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideFloorPlan());
  };

  const setBrokenImagePlaceholder = event => {
    const label = formatMessage({ id: 'view.image.notFound' });
    const imageInput = event.target;
    imageInput.src = `http://placehold.jp/24/d9d5d5/ffffff/185x185.jpg?text=${label}`;
  };

  const floorPlanAltText = formatMessage({ id: 'view.altText.floorPlan' });

  return (
    <Modal visible={showFloorPlan} onClose={() => handleClose()}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <StyledImage
          src={floorPlanImageUrl}
          alt={floorPlanAltText}
          onError={e => setBrokenImagePlaceholder(e)}
        />
      </div>
    </Modal>
  );
};

export default FloorPlanModal;
