import types from './types';

const {
  ADD, ADD_SUCCESS, ADD_FAIL,
  BOOK, BOOK_SUCCESS, BOOK_FAIL,
  SAVE_GUEST, SAVE_GUEST_SUCCESS, SAVE_GUEST_FAIL, SAVE_GUEST_RESET,
  SAVE_PAYMENT, SAVE_PAYMENT_SUCCESS, SAVE_PAYMENT_FAIL, SAVE_PAYMENT_RESET,
  SAVE_RATE, SAVE_RATE_SUCCESS, SAVE_RATE_FAIL, SAVE_RATE_RESET,
  SET_PAYMENT_UNVERIFIED, CLEAR_PAYMENT_UNVERIFIED,
  CLEAN_UP,
  SET_PAYMENT_ERROR, CLEAR_ERROR,
} = types;

export const initialState = {
  cart: null,
  loading: false,
  loaded: false,
  error: null,
  paymentError: null,
  unverifiedPayment: false,
  savingGuest: false,
  savedGuest: false,
  savingPayment: false,
  savedPayment: false,
  savingRate: false,
  savedRate: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ADD:
      return {
        ...initialState,
        loading: true,
        loaded: false,
      };
    case ADD_SUCCESS: {
      const cart = action.result;
      return {
        ...state,
        cart,
        loading: false,
        loaded: true,
        error: null,
      };
    }
    case ADD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case SAVE_GUEST:
      return {
        ...state,
        savingGuest: true,
        savedGuest: false,
        error: null,
      };
    case SAVE_GUEST_SUCCESS: {
      const cart = action.result;
      return {
        ...state,
        cart,
        savingGuest: false,
        savedGuest: true,
        error: null,
      };
    }
    case SAVE_GUEST_FAIL: {
      const { error } = action;
      return {
        ...state,
        savingGuest: false,
        savedGuest: false,
        error,
      };
    }
    case SAVE_GUEST_RESET: {
      return {
        ...state,
        savingGuest: false,
        savedGuest: false,
      };
    }
    case SAVE_PAYMENT: {
      return {
        ...state,
        savingPayment: true,
        savedPayment: false,
        error: null,
      };
    }
    case SAVE_PAYMENT_SUCCESS: {
      const cart = action.result;
      return {
        ...state,
        cart,
        savingPayment: false,
        savedPayment: true,
        error: null,
      };
    }
    case SAVE_PAYMENT_FAIL: {
      const { error } = action;
      return {
        ...state,
        savingPayment: false,
        savedPayment: false,
        error,
      };
    }
    case SAVE_PAYMENT_RESET: {
      return {
        ...state,
        savingPayment: false,
        savedPayment: false,
      };
    }
    case SAVE_RATE:
      return {
        ...state,
        savingRate: true,
        savedRate: false,
        error: null,
      };
    case SAVE_RATE_SUCCESS: {
      const cart = action.result;
      return {
        ...state,
        cart,
        savingRate: false,
        savedRate: true,
        error: null,
      };
    }
    case SAVE_RATE_FAIL: {
      const { error } = action;
      return {
        ...state,
        savingRate: false,
        savedRate: false,
        error,
      };
    }
    case SAVE_RATE_RESET: {
      return {
        ...state,
        savingRate: false,
        savedRate: false,
      };
    }
    case BOOK:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: null,
      };
    case BOOK_SUCCESS: {
      const cart = action.result;
      return {
        ...state,
        loading: false,
        loaded: true,
        cart,
      };
    }
    case BOOK_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case SET_PAYMENT_ERROR:
      return {
        ...state,
        paymentError: action.error,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        paymentError: null,
      };
    case SET_PAYMENT_UNVERIFIED:
      return {
        ...state,
        unverifiedPayment: true,
      };
    case CLEAR_PAYMENT_UNVERIFIED:
      return {
        ...state,
        unverifiedPayment: false,
      };
    case CLEAN_UP:
      return initialState;
    default:
      return state;
  }
}
